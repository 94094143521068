<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col
          md="10"
        >
          <b-row
            md="8"
          >
            <b-col>
              <b-form-group
                class="form-group-el-select"
                label="Точка отправления"
              >
                <h6>{{dimension.firstPoint.name}} ({{ dimension.firstPoint.code }})</h6>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="form-group-el-select"
                label="Конечная точка"
              >
                <h6>{{dimension.lastPoint.name}} ({{ dimension.lastPoint.code }})</h6>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            md="8"
          >
            <b-col>
              <b-form-group
                class="form-group-el-select"
                label="Основные габариты"
              >
                <h6>{{dimension.dimension.name}}</h6>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                class="form-group-el-select"
                label="Дополнительные габариты"
              >
                <span :key="optionalDimension.id" v-for="optionalDimension in dimension.optionalDimensions">
                  <h6>{{ optionalDimension.name }}</h6>
                </span>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import ElSelectClearable from '../../components/ElSelectClearable.vue';
import {routeWayDimensionView} from '../../services/api';

export default {
  components: {
    ElSelectClearable,
  },
  data() {
    return {
      dimension: null,
    };
  },
  mounted() {
    this.getDimension();
  },
  methods: {
    async getDimension() {
      this.loading = true;
      const response = await routeWayDimensionView(this.$store.state.user.ownerId, this.$route.params.dimensionId);
      if (response && response.status === 200) {
        this.dimension = response.data;
      }
      this.loading = false;
    },
  },
};
</script>
